import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
   
    theme: {
        themes: {
          light: {
            primary:  "#fff" , 
            secondary: "#757575",
            accent: "#D7CCC8",
            error: "red",
          },
         
        },
      },
    
});
